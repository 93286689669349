import React, { useState, useEffect, useRef, useContext } from 'react';
import { Play, Pause, Rewind, FastForward } from 'lucide-react';
import { analytics } from './firebaseConfig';
import { logEvent } from 'firebase/analytics';
import { saveUserProgress, getUserProgress } from './firestore';
import { AuthContext } from './AuthContext';

interface Track {
  title: string;
  url: string;
  step: number | string;
}

interface LanguageLearningPlayerProps {
  tracks: Track[];
  artworkUrl: string;
  darkMode: boolean;
}

const LanguageLearningPlayer: React.FC<LanguageLearningPlayerProps> = ({ tracks, artworkUrl, darkMode }) => {
  const [currentTrack, setCurrentTrack] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [isSpeedMenuOpen, setIsSpeedMenuOpen] = useState(false);
  const [userProgress, setUserProgress] = useState<{ [key: string]: { position: number, lastUpdated: string } } | null>(null);
  const [isProgressLoaded, setIsProgressLoaded] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  
  const speedMenuRef = useRef<HTMLDivElement>(null);
  const audioRef = useRef<HTMLAudioElement>(new Audio());
  const animationRef = useRef<number>();
  const lastSaveTimeRef = useRef<number>(0);
  const { user } = useContext(AuthContext);


  useEffect(() => {
    const loadUserProgress = async () => {
      if (user) {
        console.log('Attempting to load progress for user:', user.uid);
        const progress = await getUserProgress(user.uid);
        console.log('Loaded progress:', progress);
        setUserProgress(progress);
        
        if (progress) {
          const mostRecentTrack = Object.entries(progress).reduce((latest, current) => {
            const [step, data] = current;
            if (!latest || new Date(data.lastUpdated) > new Date(latest[1].lastUpdated)) {
              return current;
            }
            return latest;
          });

          if (mostRecentTrack) {
            const [step, data] = mostRecentTrack;
            console.log('Most recent track:', step, data);
            const trackIndex = tracks.findIndex(track => track.step.toString() === step);
            if (trackIndex !== -1) {
              setCurrentTrack(trackIndex);
              setProgress(data.position);
              console.log(`Setting current track to ${trackIndex} and progress to ${data.position}`);
            }
          }
        }
        setIsProgressLoaded(true);
      }
    };

    loadUserProgress();
  }, [user, tracks]);


  useEffect(() => {
    if (!isProgressLoaded) return;

    const audio = audioRef.current;
    audio.src = tracks[currentTrack].url;
    audio.load();
    audio.onloadedmetadata = () => {
      setDuration(audio.duration);
      if (userProgress && userProgress[tracks[currentTrack].step.toString()]) {
        const savedPosition = userProgress[tracks[currentTrack].step.toString()].position;
        audio.currentTime = savedPosition;
        setProgress(savedPosition);
        console.log(`Set initial time for track ${currentTrack} to ${savedPosition}`);
      }
      setIsInitialLoad(false);
    };

    // Log event when a new track starts playing
    logEvent(analytics, 'track_start', {
      track_title: tracks[currentTrack].title,
      track_step: tracks[currentTrack].step.toString()
    });
    
    audio.onended = () => {
      // Log event when the track finishes playing
      logEvent(analytics, 'track_complete', {
        track_title: tracks[currentTrack].title,
        track_step: tracks[currentTrack].step.toString()
      });
      // Save progress when track ends
      saveProgress();
      if (currentTrack < tracks.length - 1) {
        setCurrentTrack(prevTrack => prevTrack + 1);
      } else {
        setIsPlaying(false);
        setCurrentTrack(0);
        setProgress(0);
      }
    };

    return () => {
      audio.pause();
      audio.src = '';
    };
  }, [currentTrack, tracks, userProgress, isProgressLoaded]);



  useEffect(() => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.play().catch(error => console.error("Playback failed:", error));
      animationRef.current = requestAnimationFrame(updateProgress);
    } else {
      audio.pause();
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      // Save progress when pausing
      saveProgress();
    }

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [isPlaying, currentTrack]);

  useEffect(() => {
    return () => {
      // This function runs when the component unmounts
      if (isPlaying) {
        // Only save if audio was playing when unmounting
        saveProgress();
      }
    };
  }, []); // Empty dependency array means this effect runs only on mount and unmount
  

  useEffect(() => {
    audioRef.current.playbackRate = playbackRate;
  }, [playbackRate]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (speedMenuRef.current && !speedMenuRef.current.contains(event.target as Node)) {
        setIsSpeedMenuOpen(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  

  const SAVE_INTERVAL = 30000; // Save every 30 seconds


  const updateProgress = () => {
    const currentTime = audioRef.current.currentTime;
    setProgress(currentTime);
    
    // Check if it's time to save progress
    const now = Date.now();
    if (!isInitialLoad && now - lastSaveTimeRef.current >= SAVE_INTERVAL) {
      saveProgress();
      lastSaveTimeRef.current = now;
    }
    
    animationRef.current = requestAnimationFrame(updateProgress);
  };
  
  
  const togglePlayPause = (index: number) => {
    if (currentTrack !== index) {
      setCurrentTrack(index);
      setIsPlaying(true);
      setProgress(0);
    } else {
      setIsPlaying(!isPlaying);
    }
  };

  const skip = (seconds: number) => {
    const newTime = audioRef.current.currentTime + seconds;
    audioRef.current.currentTime = Math.max(0, Math.min(newTime, duration));
    setProgress(audioRef.current.currentTime);
  };

  

  const speeds = [0.6, 0.75, 1, 1.25, 1.5];

  const toggleSpeedMenu = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsSpeedMenuOpen(!isSpeedMenuOpen);
  };

  const handleSpeedChange = (speed: number) => {
    setPlaybackRate(speed);
    setIsSpeedMenuOpen(false);
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const getTrackBackgroundColor = (index: number) => {
    if (currentTrack === index) {
      return isPlaying
        ? (darkMode ? 'bg-amber-600' : 'bg-amber-600')
        : (darkMode ? 'bg-zinc-500' : 'bg-stone-500');
    }
    return darkMode ? 'bg-zinc-700' : 'bg-stone-200';
  };

  const saveProgress = () => {
    if (user && !isInitialLoad) {
      saveUserProgress(user.uid, tracks[currentTrack].step, audioRef.current.currentTime);
      console.log(`Progress saved for track ${currentTrack} at position ${audioRef.current.currentTime}`);
    }
  };
    
  return (
    <div className={`max-w-2xl mx-auto p-4 ${darkMode ? 'text-white' : 'text-black'}`}>

      {/* Artwork */}
      <div className="max-w-2xl mx-auto">
        <img src={artworkUrl} alt="SPANISH in 10 minutes a day AUDIO" className="w-full h-auto rounded-lg mb-4 shadow" />
      </div>

      {/* Tracks */}
      {tracks.map((track, index) => (
        <div 
          key={index} 
          className={`mb-4 p-4 rounded-lg relative ${getTrackBackgroundColor(index)} ${
            currentTrack === index ? 'text-white' : (darkMode ? 'text-stone-300' : 'text-stone-500')
          }`}
          onClick={() => togglePlayPause(index)}
        >
          <div className="flex justify-between items-start">
            <div>
              <div className="text-sm">Step {track.step}</div>
              <div className="text-2xl">{track.title}</div>
            </div>
            {currentTrack === index && (
              <div className="absolute top-1 right-3 flex items-center space-x-2 rounded-lg p-1">

                <div className="relative text-sm" ref={speedMenuRef}>
                  <button
                    onClick={toggleSpeedMenu}
                    className="flex items-center space-x-1 text-white bg-transparent">
                    <span>{playbackRate}x</span>
                    {/* <ChevronDown size={14} /> */}
                  </button>
                  {isSpeedMenuOpen && (
                    <div className="absolute top-full left-0 mt-1 bg-stone-700 rounded shadow-xl z-10">
                      {speeds.map((speed) => (
                        <button
                          key={speed}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSpeedChange(speed);
                          }}
                          className="block w-full text-left px-4 py-2 hover:bg-stone-600 text-white">
                          {speed}x
                        </button>
                      ))}
                    </div>
                  )}
                </div>

                <button onClick={(e) => { e.stopPropagation(); skip(-15); }} className="p-1">
                  <Rewind size={18} />
                </button>

                <button onClick={(e) => { e.stopPropagation(); togglePlayPause(index); }} className="p-2 rounded-full">
                  {isPlaying ? <Pause size={20} color="white" /> : <Play size={20} color="white" />}
                </button>

                <button onClick={(e) => { e.stopPropagation(); skip(15); }} className="p-1">
                  <FastForward size={18} />
                </button>
                
              </div>
            )}
          </div>
          {currentTrack === index && (
            <div className="mt-2">
              <div className="flex justify-between text-sm">
                <span>{formatTime(progress)}</span>
                <span>{formatTime(duration)}</span>
              </div>
              <div className="h-2 w-full bg-stone-200 rounded-full mt-1">
              <div 
                className={`h-2 ${isPlaying ? 'bg-amber-700' : 'bg-zinc-600'} rounded-full`}
                style={{width: `${(progress / duration) * 100}%`}}
              ></div>
              </div>
            </div>
          )}
        </div>
      ))}

      <button 
        onClick={() => {
          if (user) {
            saveUserProgress(user.uid, tracks[currentTrack].step, audioRef.current.currentTime);
          } else {
            console.log('No user logged in');
          }
        }}
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
      >
        Save Progress (Test)
      </button>

      {/* Copyright bar */}
      <div className="mt-4 flex justify-between items-center text-sm text-neutral-400 p-px">
        <span className="pl-1">© 2024 Bilingual Books, Inc.</span>
        <span className="pr-1">
          Visit us at <a href="https://www.bbks.com" className="underline">BBKS.com</a>
        </span>
      </div>
    </div>
  );
};

export default LanguageLearningPlayer;
