import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';
import Login from './Login';
import LanguageLearningPlayer from './LanguageLearningPlayer';
import { Sun, Moon } from 'lucide-react';
import { getUserDarkModeSetting } from './firestore';
import tracksData from './tracks.json';


const App: React.FC = () => {
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [darkMode, setDarkMode] = useState(() => {
    // Initialize from localStorage, but we'll update this from Firestore
    return localStorage.getItem('darkMode') === 'true';
  });

  useEffect(() => {
    const loadDarkModeSetting = async () => {
      if (user) {
        const savedDarkMode = await getUserDarkModeSetting(user.uid);
        if (savedDarkMode !== null) {
          setDarkMode(savedDarkMode);
          localStorage.setItem('darkMode', savedDarkMode.toString());
        }
      }
      setIsLoading(false);
    };

    loadDarkModeSetting();
  }, [user]);

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem('darkMode', newDarkMode.toString());
    if (user) {
      // Import and use saveUserDarkModeSetting here
      import('./firestore').then(({ saveUserDarkModeSetting }) => {
        saveUserDarkModeSetting(user.uid, newDarkMode);
      });
    }
  };

  if (!user) {
    return <Login />;
  }

  if (isLoading) {
    // You can create a more sophisticated loading component if needed
    return <div className="min-h-screen flex items-center justify-center">Loading...</div>;
  }

  return (
    <div className={`min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'}`}>
      <div className="max-w-2xl mx-auto p-3">
        <div className="flex justify-end mb-1">
          <button 
            onClick={toggleDarkMode}
            className={`p-2 rounded-full ${darkMode ? 'bg-zinc-600 text-stone-200' : 'bg-stone-200 text-stone-500'}`}
          >
            {darkMode ? <Sun size={20} /> : <Moon size={20} />}
          </button>
        </div>
        <LanguageLearningPlayer tracks={tracksData} artworkUrl="/images/title_art.png" darkMode={darkMode} />
      </div>
    </div>
  );
};

export default App;