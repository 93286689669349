import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDU_KCgTVIz7zh06JWFCKDDLHBiJLVZujA",
    authDomain: "spanishaudio.firebaseapp.com",
    projectId: "spanishaudio",
    storageBucket: "spanishaudio.appspot.com",
    messagingSenderId: "109767696982",
    appId: "1:109767696982:web:256ca831e3b94ee06311f7",
    measurementId: "G-MVVFQFQYGF"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, analytics, db };