import { db } from './firebaseConfig';
import { doc, setDoc, getDoc } from 'firebase/firestore';

export const saveUserProgress = async (userId: string, step: number | string, position: number) => {
  try {
    const userDocRef = doc(db, 'userProgress', userId);
    await setDoc(userDocRef, {
      [step.toString()]: {
        position,
        lastUpdated: new Date().toISOString()
      }
    }, { merge: true });
    console.log('Progress saved successfully');
  } catch (error) {
    console.error('Error saving progress:', error);
  }
};

export const getUserProgress = async (userId: string): Promise<{ [key: string]: { position: number, lastUpdated: string } } | null> => {
  try {
    const userDocRef = doc(db, 'userProgress', userId);
    const docSnap = await getDoc(userDocRef);
    
    if (docSnap.exists()) {
      return docSnap.data() as { [key: string]: { position: number, lastUpdated: string } };
    } else {
      console.log('No progress found for this user');
      return null;
    }
  } catch (error) {
    console.error('Error getting user progress:', error);
    return null;
  }
};

export const saveUserDarkModeSetting = async (userId: string, isDarkMode: boolean) => {
  try {
    const userPreferencesDocRef = doc(db, 'userPreferences', userId);
    await setDoc(userPreferencesDocRef, {
      darkMode: isDarkMode,
      lastUpdated: new Date().toISOString()
    }, { merge: true });
    console.log('Dark mode setting saved successfully');
  } catch (error) {
    console.error('Error saving dark mode setting:', error);
    // Fallback to local storage only
    localStorage.setItem('darkMode', isDarkMode.toString());
  }
};

export const getUserDarkModeSetting = async (userId: string): Promise<boolean | null> => {
  try {
    const userPreferencesDocRef = doc(db, 'userPreferences', userId);
    const docSnap = await getDoc(userPreferencesDocRef);
    
    if (docSnap.exists() && docSnap.data().darkMode !== undefined) {
      return docSnap.data().darkMode as boolean;
    } else {
      console.log('No dark mode setting found for this user');
      // Fallback to local storage
      const localSetting = localStorage.getItem('darkMode');
      return localSetting ? localSetting === 'true' : null;
    }
  } catch (error) {
    console.error('Error getting user dark mode setting:', error);
    // Fallback to local storage
    const localSetting = localStorage.getItem('darkMode');
    return localSetting ? localSetting === 'true' : null;
  }
};
